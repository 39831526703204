import * as React from "react";
import {
  Badge,
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Button, Card, Label, WarningDialog } from "shared";

import {
  HARD_STOPS_TRIAGE_SECTIONS,
  HARD_STOPS_PROVIDER_SECTIONS,
  HARD_STOPS_SUBSECTIONS,
  HARD_STOPS_SECTIONS_ROUTES,
} from "./constants";
import { ProviderHardStopsResponse, TriageHardStopsResponse } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleHardStops, ExclamationCircleHardStops } from "icons";
import {
  UseChartHardStopsResponse,
  useUpdateChartApplicationStatus,
} from "modules/charts-shared/api";
import { ChartRouteBaseParams } from "modules/charts-shared/types";
import { useUserRoles } from "contexts/UserRoles";
import { UserType } from "modules/identity";
import { useFormContext } from "react-hook-form";
import { formMessages } from "messages";
import { extractApiErrorMessage } from "utils";
import { ChartType } from "types";
import { ProviderChartCode, TriageChartCode } from "enums";

const SECTION_CODE = "SDIS";
const rolesAllowedToCompleteChart: UserType[] = [
  "Administrator",
  "SupervisingProvider",
  "Provider",
  "Nurse",
];

type HardStops =
  | TriageHardStopsResponse
  | ProviderHardStopsResponse
  | undefined;

type SidebarHardStopProps = {
  patientDispositioned: boolean;
  chartCode: TriageChartCode | ProviderChartCode;
  chartType: ChartType;
  data: UseChartHardStopsResponse | undefined;
};

type ChartHardStopsSectionProps = {
  key: number;
  sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS;
  section:
    | keyof typeof HARD_STOPS_PROVIDER_SECTIONS
    | keyof typeof HARD_STOPS_TRIAGE_SECTIONS;
  data: HardStops;
};

const validateElementRestrictions = (section: string, data: HardStops) => {
  const restrictions = {
    medsAndIV: data?.hasMedAndIvOrders,
    ekg: data?.hasEKGOrders,
    labs: data?.hasLabsOrders,
    treatments: data?.hasTreatmentsOrders,
    radiology: data?.hasRadiologyOrders,
  };

  const prop = section as keyof typeof restrictions;
  return restrictions[prop] ?? true;
};

function ChartHardStopsSection(props: ChartHardStopsSectionProps) {
  const { key, section, sections, data } = props;
  const element = data ? data[section as keyof typeof data] : undefined;

  const navigate = useNavigate();

  if (
    typeof element === "undefined" ||
    !validateElementRestrictions(section, data)
  )
    return null;

  return (
    <Box key={key}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Label>{sections[section as keyof typeof sections]}</Label>
        <Button
          variant="label"
          onClick={() =>
            navigate(
              `./../${
                HARD_STOPS_SECTIONS_ROUTES[
                  section as keyof typeof HARD_STOPS_SECTIONS_ROUTES
                ]
              }`
            )
          }
          color="blue"
        >
          Go to Section
        </Button>
      </Box>

      <Card
        sx={{
          width: "99%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 1.5,
          px: 5,
          flexDirection: "column",
        }}
      >
        {Object.keys(element).map((prop, index) => {
          const value = element[prop as keyof typeof element];
          if (typeof value === "undefined") return null;

          return (
            <Flex w="100%" columnGap="17px" alignItems="center" key={index}>
              <Icon
                as={value ? CheckCircleHardStops : ExclamationCircleHardStops}
                alignSelf="center"
                fontSize={"26px"}
                color={value ? "green" : "red"}
              />
              <Flex
                borderBottom={
                  index === Object.keys(element).length - 1
                    ? "0px"
                    : "1px solid"
                }
                borderColor="gray.450"
                w="100%"
                justify="start"
                p="1rem 0 1rem 1rem"
                alignItems="center"
              >
                {
                  HARD_STOPS_SUBSECTIONS[
                    prop as keyof typeof HARD_STOPS_SUBSECTIONS
                  ]
                }
              </Flex>
            </Flex>
          );
        })}
      </Card>
    </Box>
  );
}

function SidebarHardStops(props: SidebarHardStopProps) {
  const { data, chartCode, chartType, patientDispositioned } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const { roles } = useUserRoles();
  const { getValues } = useFormContext();

  const chartStatus = getValues("chartStatus");

  const [warningDialogMessage, setWarningDialogMessage] = React.useState("");
  const [warningDialogAction, setWarningDialogAction] =
    React.useState<() => void>();

  const pendingHardStops = React.useMemo(() => {
    const hardStopsData =
      chartType === "Chart" ? data?.providerHardStops : data?.triageHardStops;

    if (!hardStopsData) return 0;

    const {
      hasEKGOrders,
      hasLabsOrders,
      hasMedAndIvOrders,
      hasRadiologyOrders,
      hasTreatmentsOrders,
      ...rest
    } = hardStopsData;

    return Object.entries(rest).reduce((count, [_key, value]) => {
      return count + Object.values(value).filter((v) => !v).length;
    }, 0);
  }, [chartType, data]);

  const areAllHardStopsTrue = React.useMemo(() => {
    const hardStopsData =
      chartType === "Chart" ? data?.providerHardStops : data?.triageHardStops;

    if (!hardStopsData) return false;

    // Ignore this properties when validating
    const {
      hasEKGOrders,
      hasLabsOrders,
      hasMedAndIvOrders,
      hasRadiologyOrders,
      hasTreatmentsOrders,
      ...rest
    } = hardStopsData;

    return Object.entries(rest).every(([_key, value]) => {
      return Object.values(value).every((v) => !!v);
    });
  }, [chartType, data]);

  const {
    mutateAsync: updateChartStatus,
    isLoading: isLoadingChartStatusUpdate,
  } = useUpdateChartApplicationStatus({
    encounterId,
    chartCode,
    sectionId: SECTION_CODE,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChangeChartStatusToComplete = async () => {
    try {
      await updateChartStatus("Completed");
      toast({ description: formMessages.updateSuccess("ChartStatus") });

      onCloseWarningDialog();
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const onClickCompleteChart = () =>
    areAllHardStopsTrue && onCompleteChartAction();

  const onCompleteChartAction = () => {
    setWarningDialogMessage(
      "Are you sure you want to mark this Chart as Complete?"
    );
    setWarningDialogAction(() => handleChangeChartStatusToComplete);
    onOpen();
  };

  const onCloseWarningDialog = () => {
    setWarningDialogMessage("");
    setWarningDialogAction(undefined);
    onClose();
  };

  const sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS =
    chartType === "Triage"
      ? HARD_STOPS_TRIAGE_SECTIONS
      : HARD_STOPS_PROVIDER_SECTIONS;

  return (
    <Box
      width="25vw"
      bg="gray.200"
      height="100vh"
      position="fixed"
      top="0"
      right="0"
      display="flex"
      flexDirection="column"
    >
      <Box
        sx={{
          height: "8vh",
          position: "sticky",
          top: 0,
          zIndex: 100,
          backgroundColor: "gray.200",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            padding: "20px",
          }}
        >
          <Text
            sx={{
              fontWeight: "600",
              fontSize: "1.2625rem",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {chartType === "Triage" ? "Nurse" : "Provider"} Chart Hard Stops
          </Text>
          <Badge
            bg="red"
            color="white"
            borderRadius="20px"
            padding="0.325rem 0.6rem"
          >
            {pendingHardStops}
          </Badge>
        </Box>
        <Divider />
      </Box>

      <Box
        sx={{
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "auto",
          backgroundColor: "gray.200",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {Object.keys(sections).map((key, index) => (
            <ChartHardStopsSection
              key={index}
              sections={sections}
              section={key as keyof typeof HARD_STOPS_PROVIDER_SECTIONS}
              data={
                chartType === "Chart"
                  ? data?.providerHardStops
                  : data?.triageHardStops
              }
            />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          height: "12vh",
          backgroundColor: "white",
        }}
      >
        <Divider />
        <Flex justifyContent="center" width="100%" pt={2} pb={2} gap={30}>
          <Badge
            bg="red"
            color="white"
            borderRadius="20px"
            padding="0.325rem .85rem"
            fontSize=".875rem"
            textTransform="capitalize"
          >
            {patientDispositioned
              ? "Patient Dispositioned"
              : "Patient Not Dispositioned"}
          </Badge>
        </Flex>
        <Box
          sx={{
            backgroundColor: "gray.400",
          }}
        >
          <Flex justifyContent="center" width="100%" pt={4} pb={4} gap={30}>
            <Button
              variant="outlineSquared"
              borderRadius="25px"
              width="320px"
              isDisabled={
                !roles.some((role) =>
                  rolesAllowedToCompleteChart.includes(role)
                ) ||
                isLoadingChartStatusUpdate ||
                chartStatus === "Completed" ||
                areAllHardStopsTrue === false
              }
              onClick={onClickCompleteChart}
              isLoading={isLoadingChartStatusUpdate}
            >
              Chart Complete
            </Button>

            <WarningDialog
              isOpen={isOpen}
              onCancel={onCloseWarningDialog}
              title="Warning"
              mainText={warningDialogMessage}
              onClose={onCloseWarningDialog}
              onAction={() => warningDialogAction && warningDialogAction()}
              cancelLabel="No"
              actionLabel="Yes"
              blockScrollOnMount={false}
              cancelButtonProps={{ color: "red" }}
              actionButtonProps={{ color: "blue" }}
            />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

export { SidebarHardStops };
