import * as React from "react";

import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { Card, CardProps, InfiniteList, SearchBar } from "shared";
import {
  BubbleChatIndicator,
  Countdown,
  DownArrowFilled,
  ExclamationTriangle,
  InfoRoundOutline,
  LineGraph,
  NurseHat,
  PersonTalking,
  RequestDisposition,
  UpArrowFilled,
} from "icons";
import {
  EmrLabs,
  EmrMedicalHX,
  EmrRadiology,
  EmrTakeVitals,
  EmrVitals,
} from "@medstonetech/slate-icons";
import { InProcessPatientFilter, TimerStatus } from "enums";
import {
  InProcessAssignOptions,
  InProcessSortOptions,
  useInProcessEncounters,
} from "modules/in-process/api";
import {
  InProcessOptionsRadio,
  InProcessOptionsRadioItem,
} from "../InProcessOptionsRadio";
import { extractApiErrorMessage, msToTimeComponents } from "utils";
import { useCountdown, useToast } from "hooks";

import { ChartPatientCard } from "modules/charts-shared";
import { DIET_TYPE_ICON } from "modules/in-process/constants";
import {
  EncounterListItem,
  PatientOrderHistoryStatus,
} from "modules/in-process/types";
import { PatientDetailsModal } from "./PatientDetailsModal";
import { PatientDietModal } from "./PatientDietModal";
import { PatientNotesModal } from "./PatientNotesModal";
import { addMilliseconds } from "date-fns";
import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import { useUserRoles } from "contexts/UserRoles";
import { UserType } from "modules/identity";

const PATIENT_ORDER_HISTORY_ICON_COLOR: Record<
  PatientOrderHistoryStatus,
  string
> = {
  AllCompleted: "green",
  HasOrdered: "orange",
  None: "gray.450",
};

type InProcessPatientCardProps = {
  patient: EncounterListItem;
  isDischarged?: boolean;
  onDietButtonClick?: (patient: EncounterListItem) => void;
  onApptNotesButtonClick?: (patient: EncounterListItem) => void;
  onInfoButtonClick?: (patient: EncounterListItem) => void;
} & CardProps;

type InProcessPatientListProps = {
  filter?: string;
  searchString?: string;
  orderBy?: InProcessSortOptions;
  assignation?: InProcessAssignOptions;
  searchOpenCharts: boolean;
  searchDisposition: boolean;
  descending?: boolean;
} & BoxProps;

type PatientListProps = {
  filter?: InProcessPatientFilter;
  orderBy?: InProcessSortOptions;
  searchOpenCharts?: boolean;
  searchDisposition?: boolean;
};

type PatientTimerProps = {
  icon: React.ReactElement;
  set?: string;
  time?: string;
  timerStatus: TimerStatus;
  countdown?: {
    millisecondsLeft: number | null;
    status?: "Started" | "NotStarted" | "Paused";
  };
  fullName?: string;
  onIconClick?: () => void;
  isIconDisabled?: boolean;
} & BoxProps;

type VitalsCountdownProps = {
  milliseconds: number;
};

function VitalsCountdown(props: VitalsCountdownProps) {
  const { milliseconds } = props;
  const dateCountdown = React.useMemo(() => {
    return addMilliseconds(new Date(), milliseconds || 0);
  }, [milliseconds]);
  const countdown = useCountdown(dateCountdown, 1);

  const { hours, minutes } = React.useMemo(
    () => msToTimeComponents(countdown),
    [countdown]
  );

  return <>{`${hours}h ${minutes}m`}</>;
}

function PatientTimer(props: PatientTimerProps) {
  const {
    icon,
    set,
    time,
    timerStatus,
    countdown,
    onIconClick,
    isIconDisabled,
    fullName,
    ...rest
  } = props;

  return (
    <Flex
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      position="relative"
      minWidth="90px"
      width="90px"
      {...rest}
    >
      <Tooltip label={set} bgColor="black">
        <chakra.span
          fontSize="0.75rem"
          color="gray.600"
          fontWeight="700"
          position="absolute"
          width="100%"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign="center"
          top={0}
        >
          {set}
        </chakra.span>
      </Tooltip>
      <IconButton
        size="iconSm"
        aria-label="Appt. Notes"
        variant="ghost"
        onClick={onIconClick}
        icon={React.cloneElement(icon, {
          fontSize: "1.25rem",
        })}
        isDisabled={isIconDisabled}
      />

      <chakra.span
        fontSize="0.75rem"
        color="gray.600"
        fontWeight="700"
        position="absolute"
        width="100%"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        textAlign="center"
        bottom={0}
      >
        {countdown &&
        countdown.status !== "NotStarted" &&
        countdown.millisecondsLeft !== null ? (
          <HStack justifyContent="center">
            <Icon
              as={Countdown}
              color="gray.600"
              marginEnd="7.58px"
              h="12.56px"
              w="12.42px"
            />
            <VitalsCountdown milliseconds={countdown.millisecondsLeft || 0} />
          </HStack>
        ) : (
          time
        )}
      </chakra.span>
    </Flex>
  );
}

type ButtonModalProps = {
  icon: React.ReactElement;
  set?: string;
  onIconClick?: () => void;
};

type IconButtonModalProps = {
  patient: EncounterListItem;
  icon: React.ReactElement;
};

type InProcessPatientDataProps = {
  patient: EncounterListItem;
};

function InProcessIconButton(props: ButtonModalProps) {
  const { icon, onIconClick, set, ...rest } = props;

  return (
    <Flex
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      position="relative"
      minWidth="90px"
      width="90px"
      {...rest}
    >
      {set && (
        <Text
          position="absolute"
          left="45%"
          top="1"
          fontSize="0.75rem"
          color="gray.600"
          fontWeight="700"
        >
          {set}
        </Text>
      )}

      <IconButton
        size="iconSm"
        aria-label="Appt. Notes"
        variant="ghost"
        onClick={onIconClick}
        icon={React.cloneElement(icon, {
          fontSize: "1.45rem",
        })}
      />
    </Flex>
  );
}

function IconButtonDietModal(props: IconButtonModalProps) {
  const { patient, icon } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <InProcessIconButton
        icon={icon}
        onIconClick={onOpen}
        set={
          patient.foodOrdersCount > 0
            ? patient.foodOrdersCount.toString()
            : undefined
        }
      />
      {isOpen && patient && (
        <PatientDietModal
          isModalOpen={isOpen}
          onModalClose={onClose}
          patient={{
            encounterId: patient.encounterId,
            fullName: patient.fullName ?? "",
            hasDrugAllergies: patient.hasDrugAllergies,
            hasFoodAllergies: patient.hasFoodAllergies,
          }}
        />
      )}
    </>
  );
}

function IconButtonApptNotesModal(props: IconButtonModalProps) {
  const { patient, icon } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <InProcessIconButton icon={icon} onIconClick={onOpen} />
      <PatientNotesModal isOpen={isOpen} onClose={onClose} patient={patient} />
    </>
  );
}

type GetChartRouteParams = {
  roles?: UserType[];
  nursingChart?: {
    timer: string | null;
    nurse: string | null;
    lastChartCode: string | null;
  } | null;
  providerChart?: {
    timer: string | null;
    provider: string | null;
    lastChartCode: string | null;
  } | null;
  sectionRoute: string;
  encounterId: string;
  chartType?: "Demographic" | "Triage" | "Provider";
  routeParams?: string;
};

function getChartRoute(params: GetChartRouteParams) {
  const {
    roles,
    nursingChart,
    providerChart,
    encounterId,
    sectionRoute,
    chartType,
    routeParams,
  } = params;
  const nursingChartCode = nursingChart?.lastChartCode;
  const providerChartCode = providerChart?.lastChartCode;
  const mainRoute = window.location.hash.split("/")[1];

  const navigateToNurseChart = () =>
    `/${mainRoute}/charts/${encounterId}/triage/${nursingChartCode}/${sectionRoute}${
      routeParams ? `?${routeParams}` : ""
    }`;

  const navigateToProviderChart = () =>
    `/${mainRoute}/charts/${encounterId}/chart/${providerChartCode}/${sectionRoute}${
      routeParams ? `?${routeParams}` : ""
    }`;

  const navigateToDefaultChart = () =>
    `/${mainRoute}/charts/${encounterId}/general/${sectionRoute}?createChart=triage${
      routeParams ? `?${routeParams}` : ""
    }`;

  const navigateToCreateNurseChart = () =>
    `/${mainRoute}/charts/${encounterId}/general/${sectionRoute}?createChart=triage${
      routeParams ? `?${routeParams}` : ""
    }`;

  const navigateToCreateProviderChart = () =>
    `/${mainRoute}/charts/${encounterId}/general/${sectionRoute}?createChart=provider${
      routeParams ? `?${routeParams}` : ""
    }`;

  const isProvider =
    roles?.includes("Provider") || roles?.includes("SupervisingProvider");
  const isNurse = roles?.includes("Nurse");
  const hasNursingChartCode = !!nursingChartCode;
  const hasProviderChartCode = !!providerChartCode;

  switch (chartType) {
    case "Provider":
      if (hasProviderChartCode) {
        return navigateToProviderChart();
      } else {
        return navigateToCreateProviderChart();
      }
    case "Triage":
      if (hasNursingChartCode) {
        return navigateToNurseChart();
      } else {
        return navigateToCreateNurseChart();
      }
    default:
      if (isNurse && hasNursingChartCode) {
        return navigateToNurseChart();
      } else if (isProvider && hasProviderChartCode) {
        return navigateToProviderChart();
      } else if (hasNursingChartCode) {
        return navigateToNurseChart();
      } else if (hasProviderChartCode) {
        return navigateToProviderChart();
      } else {
        return navigateToDefaultChart();
      }
  }
}

function InProcessPatientData(props: InProcessPatientDataProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { patient } = props;
  const {
    entitledToObservation,
    fullName,
    age,
    gender,
    overallTime,
    roomBed,
    acuity,
    painLevel,
    diet,
    encounterId,
    hasFoodAllergies,
    isVip,
    painProtocol,
    chiefComplaint,
  } = patient;

  return (
    <>
      <Button variant="label" margin="0 10px" onClick={onOpen} minW="25%">
        <ChartPatientCard
          name={fullName || ""}
          age={age}
          gender={gender || ""}
          encounterId={encounterId}
          overallTimer={overallTime || "0:00"}
          timerStatus="AGREEABLE"
          roomNumber={roomBed?.bedCode || ""}
          acuity={acuity}
          painLevel={painLevel}
          dietType={diet}
          hasFoodAllergies={hasFoodAllergies}
          entitledToObservation={entitledToObservation}
          isVip={isVip}
          isStp={painProtocol}
          w="100%"
          chiefComplaint={chiefComplaint}
        />
      </Button>
      <PatientDetailsModal
        encounterId={encounterId}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

function InProcessPatientItem(props: InProcessPatientCardProps) {
  const { isDischarged, patient, ...restProps } = props;
  const {
    hasAppointmentNotes,
    diet,
    nursingChart,
    providerChart,
    hasAllergiesOrMedicationsRx,
    hasProviderMedicalHistory,
    hasTriageMedicalHistory,
    vitals,
    encounterId,
    fullName,
    nurseDisposition,
    providerDisposition,
    orderHistoryStatus,
  } = patient;
  const navigate = useNavigate();

  const { roles } = useUserRoles();

  const medicalHistoryColor = () => {
    if (hasAllergiesOrMedicationsRx) return "red";
    if (hasTriageMedicalHistory || hasProviderMedicalHistory) return "cyan";
    return "gray.450";
  };

  const hasTriageChartCode = !!nursingChart?.lastChartCode;
  const hasProviderChartCode = !!providerChart?.lastChartCode;
  const hasChart = hasTriageChartCode || hasProviderChartCode;

  const getOrderHistoryIcon = () => {
    if (!orderHistoryStatus)
      return (
        <>
          <Icon color={"gray.450"} as={EmrRadiology} fontSize="1.25rem" />
          <Icon color={"gray.450"} as={EmrLabs} fontSize="1.25rem" />
        </>
      );

    if (orderHistoryStatus?.hasCancellationRequests)
      return <Icon as={BubbleChatIndicator} color="red" fontSize="22px" />;

    return (
      <>
        <Icon
          color={
            PATIENT_ORDER_HISTORY_ICON_COLOR[orderHistoryStatus.radiology] ||
            "gray.450"
          }
          as={EmrRadiology}
          fontSize="1.25rem"
        />
        <Icon
          color={
            PATIENT_ORDER_HISTORY_ICON_COLOR[orderHistoryStatus.labs] ||
            "gray.450"
          }
          as={EmrLabs}
          fontSize="1.25rem"
        />
      </>
    );
  };

  return (
    <Card boxShadow="none" padding="4px 0" {...restProps}>
      <Flex
        height="85px"
        alignItems="center"
        justifyContent="space-between"
        pt="10px"
      >
        <InProcessPatientData patient={patient} />
        <Flex
          columnGap="20px"
          justifyContent="space-around"
          height="100%"
          flex={1}
        >
          <IconButtonApptNotesModal
            patient={patient}
            icon={
              <Icon
                color={hasAppointmentNotes ? "cyan" : "gray.450"}
                as={PersonTalking}
              />
            }
          />
          <PatientTimer
            timerStatus="UNKNOWN"
            icon={<Icon color="cyan" as={InfoRoundOutline} />}
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "patient-info",
                  nursingChart,
                  providerChart,
                }),
                {
                  state: {
                    fromRoute: location.pathname,
                    fromLabel: "Back",
                    isPastEncounter: isDischarged,
                  },
                }
              )
            }
          />
          <PatientTimer
            timerStatus="UNKNOWN"
            set={vitals?.timer || ""}
            countdown={{
              millisecondsLeft: vitals?.millisecondsRemaining || null,
              status: vitals?.status,
            }}
            fullName={fullName || ""}
            icon={
              <Icon
                color={vitals?.lastCheck ? "cyan" : "gray.450"}
                as={EmrTakeVitals}
              />
            }
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "vitals",
                  nursingChart,
                  providerChart,
                })
              )
            }
          />
          <PatientTimer
            timerStatus="UNKNOWN"
            icon={<Icon color={medicalHistoryColor()} as={EmrMedicalHX} />}
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "medical-hx",
                  nursingChart,
                  providerChart,
                })
              )
            }
            isIconDisabled={!hasChart}
          />
          <PatientTimer
            time={nursingChart?.timer || ""}
            set={nursingChart?.nurse || ""}
            timerStatus="UNKNOWN"
            icon={
              nurseDisposition ? (
                <Icon color={"orange"} as={RequestDisposition} />
              ) : (
                <Icon
                  color={nursingChart?.lastChartCode ? "cyan" : "gray.450"}
                  as={NurseHat}
                />
              )
            }
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "patient-info",
                  nursingChart,
                  chartType: "Triage",
                })
              )
            }
          />
          <PatientTimer
            time={providerChart?.timer || ""}
            set={providerChart?.provider || ""}
            timerStatus="UNKNOWN"
            icon={
              providerDisposition ? (
                <Icon color={"orange"} as={RequestDisposition} />
              ) : (
                <Icon
                  color={providerChart?.lastChartCode ? "cyan" : "gray.450"}
                  as={EmrVitals}
                />
              )
            }
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "hpi",
                  providerChart,
                  chartType: "Provider",
                })
              )
            }
          />
          <PatientTimer
            timerStatus="UNKNOWN"
            icon={
              <Flex direction="row" gap="0.5rem" alignItems="center">
                {getOrderHistoryIcon()}
              </Flex>
            }
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "order-history",
                  nursingChart,
                  providerChart,
                })
              )
            }
            isIconDisabled={!hasChart}
          />
          <PatientTimer
            timerStatus="UNKNOWN"
            icon={
              <Icon color={hasChart ? "cyan" : "gray.450"} as={LineGraph} />
            }
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "progress",
                  nursingChart,
                  providerChart,
                })
              )
            }
            isIconDisabled={!hasChart}
          />
          <IconButtonDietModal patient={patient} icon={DIET_TYPE_ICON[diet]} />
          <PatientTimer
            timerStatus="UNKNOWN"
            icon={
              <Icon
                color={
                  hasChart
                    ? hasAllergiesOrMedicationsRx
                      ? "red"
                      : "cyan"
                    : "gray.450"
                }
                as={ExclamationTriangle}
              />
            }
            onIconClick={() =>
              navigate(
                getChartRoute({
                  roles,
                  encounterId,
                  sectionRoute: "allergies-medications-rx",
                  nursingChart,
                  providerChart,
                })
              )
            }
          />
        </Flex>
      </Flex>
    </Card>
  );
}

const headerSectionStyles: BoxProps & { variant: string } = {
  variant: "ghost",
  fontSize: "0.9375rem",
  display: "flex",
  minWidth: "100px",
  width: "100px",
  py: "20px",
  justifyContent: "center",
  textAlign: "center",
};

function InProcessPatientList(props: InProcessPatientListProps) {
  const {
    filter,
    searchOpenCharts = false,
    searchDisposition = false,
    orderBy = "room",
    assignation = "AssignedToMe",
    searchString,
    descending,
    ...rest
  } = props;

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage, error } =
    useInProcessEncounters({
      patientStatus: filter,
      search: searchString,
      sort: orderBy,
      descending,
      assignation,
      searchOpenCharts,
      searchDisposition,
    });

  const toast = useToast();
  const hasData =
    data && data.pages.some((page) => page.data.content.length > 0);
  const showNoResults = !isLoading && !hasData;

  const fetchMore = React.useCallback(async () => {
    try {
      if (!isLoading && !isFetching) {
        await fetchNextPage();
      }
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, isLoading, isFetching, toast]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <Box {...rest}>
      {showNoResults ? (
        <Box width="100%">
          <Box
            textAlign="center"
            fontSize="1.65rem"
            fontWeight="bold"
            color="gray.650"
          >
            No results
          </Box>
        </Box>
      ) : (
        <InfiniteList
          fetchMore={fetchMore}
          hasMore={!!hasNextPage}
          isLoading={isLoading || isFetching}
          renderRow={(patient) => (
            <InProcessPatientItem
              patient={patient}
              key={patient.encounterId}
              minWidth="1532px"
              isDischarged={filter === "Discharged"}
            />
          )}
          rows={
            data?.pages.reduce<EncounterListItem[]>(
              (accum, curr) => [...accum, ...curr.data.content],
              []
            ) || []
          }
          spacing="10px"
          paddingBottom="50px"
        />
      )}
    </Box>
  );
}

function InProcessPatients(props: PatientListProps) {
  const {
    filter = "",
    orderBy = "room",
    searchOpenCharts = false,
    searchDisposition = false,
  } = props;

  const [searchString, setSearchString] = React.useState<string | undefined>(
    undefined
  );
  const [descending, setDescending] = React.useState<boolean | undefined>(
    false
  );
  const [assignedTo, setAssignedTo] =
    React.useState<InProcessAssignOptions>("All");

  const onSearch = React.useMemo(
    () =>
      debounce<React.ChangeEventHandler<HTMLInputElement>>(1000, (e) => {
        setSearchString(e.target.value || undefined);
      }),
    []
  );

  React.useEffect(() => {
    return () => {
      const onSearchDebounce = onSearch;
      onSearchDebounce.cancel();
    };
  }, [onSearch]);

  const handleDescendingClick = () => {
    setDescending((prevValue) => !prevValue);
  };

  return (
    <Flex direction="column" height="100%">
      {!searchOpenCharts && !searchDisposition && (
        <VStack alignItems="center" pt="30px">
          <InProcessOptionsRadio
            maxW="668px"
            minW="550px"
            h="32px"
            value={assignedTo}
            onChange={setAssignedTo as (filter: string) => void}
          >
            <InProcessOptionsRadioItem value="All" flex="1">
              All
            </InProcessOptionsRadioItem>
            <InProcessOptionsRadioItem value="AssignedToMe" flex="1">
              Assigned to Me
            </InProcessOptionsRadioItem>
            <InProcessOptionsRadioItem value="Unassigned" flex="1">
              Unassigned
            </InProcessOptionsRadioItem>
          </InProcessOptionsRadio>
        </VStack>
      )}

      <Box
        margin={
          searchOpenCharts || searchDisposition
            ? "73px 16px 7px"
            : "13px 16px 7px"
        }
      >
        <SearchBar onChange={onSearch} placeholder="Search Patients" />
      </Box>
      <Box
        overflowX="auto"
        flex="1"
        maxHeight="calc(100vh - 150px)"
        overflowY="auto"
        margin="16px"
      >
        <Card
          bg="gray.200"
          minWidth="1532px"
          margin="0"
          position="sticky"
          top="0"
          zIndex="10"
          height="64px"
          boxShadow="sm"
          padding="0 16px"
        >
          <Flex alignItems="center" pr="10px" height="100%">
            <Flex
              {...headerSectionStyles}
              justifyContent="start"
              minW="25%"
              alignItems="center"
            >
              Patient Snapshot
              <IconButton
                size="iconSm"
                aria-label="Ascend Descend"
                variant="ghost"
                onClick={handleDescendingClick}
                icon={
                  <Icon
                    marginLeft="1rem"
                    marginTop="4px"
                    color="gray"
                    as={descending ? DownArrowFilled : UpArrowFilled}
                  />
                }
              />
            </Flex>
            <Flex alignItems="center" justifyContent="space-around" flex={1}>
              <Box {...headerSectionStyles}>Appt. Notes</Box>
              <Box {...headerSectionStyles}>Patient Info</Box>
              <Box {...headerSectionStyles}>Vitals</Box>
              <Box {...headerSectionStyles}>Medical History</Box>
              <Box {...headerSectionStyles}>Nursing Chart</Box>
              <Box {...headerSectionStyles}>Provider Chart</Box>
              <Box {...headerSectionStyles}>Order History</Box>
              <Box {...headerSectionStyles}>Progress Notes</Box>
              <Box {...headerSectionStyles}>
                Food <br /> & Diet
              </Box>
              <Box {...headerSectionStyles}>
                Allergies <br />& Medications
              </Box>
            </Flex>
          </Flex>
        </Card>

        <InProcessPatientList
          filter={filter}
          searchOpenCharts={searchOpenCharts}
          searchDisposition={searchDisposition}
          assignation={assignedTo}
          orderBy={orderBy}
          searchString={searchString}
          minWidth="1532px"
          marginTop="10px"
          padding="100 0 100 0"
          descending={descending}
        />
      </Box>
    </Flex>
  );
}

export { InProcessPatients, getChartRoute };
