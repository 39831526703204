import * as React from "react";

import { Button, Card, Loading, StaffAvatar, WarningDialog } from "shared";
import {
  ChartRouteBaseParams,
  SharedChartBaseProps,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
  SidebarHardStops,
  useChartHardStops,
} from "modules";
import { EmrProfileComplete, EmrSignature } from "@medstonetech/slate-icons";
import {
  Box,
  Flex,
  Icon,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { CONTAINER_PADDING } from "modules/triage/shared/actions/constants";
import { ChartForm } from "modules/charts-shared-v2/components";
import { DispositionSignatureSubsection } from "modules/charts-shared/components/disposition/DispositionSignatureSubsection";
import { FormPromptWithSaveProps } from "shared/prompt";
import { FormProvider } from "react-hook-form";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useChartDispositionForm } from "modules/charts-shared/hooks/userChartDispositionForm";
import {
  useEncounterSideInfo,
  useUpdateEncounterDisposition,
} from "modules/reception/api";

const SECTION_CODE = "SDIS";

function N07Disposition(props: SharedChartBaseProps) {
  const { chartCode, chartType, isReadOnly = false } = props;
  const toast = useToast();
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  const { data: encounter } = useEncounterSideInfo(encounterId);

  const [warningDialogMessage, setWarningDialogMessage] = React.useState("");
  const [warningDialogSubMessage, setWarningDialogSubMessage] =
    React.useState("");
  const [warningDialogAction, setWarningDialogAction] =
    React.useState<() => void>();

  const { getIsLoading, onSubmit, updateIsLoading, useFormContext } =
    useChartDispositionForm({
      sectionId: SECTION_CODE,
      sectionLabel: "Disposition",
      chartCode,
    });

  const {
    getValues,
    formState: { isDirty },
    control,
  } = useFormContext;

  const signatures = getValues("signatures");

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      await onSubmit();
      toast({ description: formMessages.updateSuccess("Disposition") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const isEmpty = () => {
    const values = getValues();
    return Object.keys(values).length === 0;
  };

  const {
    mutateAsync: updateEncounterDisposition,
    isLoading: isLoadingEncounterDispositionUpdate,
  } = useUpdateEncounterDisposition(encounterId, chartType);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data: hardStops } = useChartHardStops({
    chartCode,
    chartType,
    encounterId,
  });

  const handleChangeDispositionStatus = async () => {
    try {
      await updateEncounterDisposition({ encounterId });
      toast({ description: formMessages.updateSuccess("Status") });

      onCloseWarningDialog();
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const onDisposistionPatientAction = () => {
    setWarningDialogMessage(
      `This patient will be marked as ${
        !encounter?.data?.nurseDisposition
          ? "dispositioned"
          : "not dispositioned"
      } in the nurse charts`
    );
    setWarningDialogSubMessage("Are you sure you want to update this Patient?");
    setWarningDialogAction(() => handleChangeDispositionStatus);
    onOpen();
  };

  const onCloseWarningDialog = () => {
    setWarningDialogMessage("");
    setWarningDialogAction(undefined);
    onClose();
  };

  if (getIsLoading || isEmpty()) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <Box display="flex" flexDirection="row" width="100%">
        <Box width="60vw" padding="4">
          <ChartForm
            onSubmit={onSubmit}
            onRouteChangeSave={onRouteChangeSave}
            isDirty={isDirty}
            isPromptSaveLoading={updateIsLoading}
            disabled={isReadOnly}
          >
            <SharedChartSectionHeader
              icon={<Icon as={EmrSignature} />}
              showActionsBtns
              isLoading={updateIsLoading}
              showNotesBtn={chartType === "Triage" ? false : undefined}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
            >
              Disposition {isReadOnly ? "(Read Only)" : ""}
            </SharedChartSectionHeader>
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q001"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                />
              }
              content={
                <ChartSubsectionContent>
                  <ChartRow display="flex">
                    <ChartRowElement
                      type="label"
                      label="intake:"
                      fontStyle="italic"
                      textDecor="underline"
                      flex="1"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A001"
                      size="xl"
                    />
                    <ChartRowElement
                      type="label"
                      label="output:"
                      fontStyle="italic"
                      textDecor="underline"
                      flex="1"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A002"
                      size="xl"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A013"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="IV discotinued"
                      name="subsections.Q001.T001xSDISQ001A003"
                    />
                    <ChartRowElement
                      type="option"
                      label="Lock discontinued"
                      name="subsections.Q001.T001xSDISQ001A014"
                    />
                    <ChartRowElement
                      type="option"
                      label="cath intact"
                      name="subsections.Q001.T001xSDISQ001A004"
                    />
                    <ChartRowElement
                      type="option"
                      label="no swelling"
                      name="subsections.Q001.T001xSDISQ001A005"
                    />
                    <ChartRowElement
                      type="option"
                      label="no redness"
                      name="subsections.Q001.T001xSDISQ001A006"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A007"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement type="label" label="nausea" />
                    <ChartRowElement type="label" label="vomiting x" />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A008"
                      size="lg"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A009"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement type="label" label="other" />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A010"
                    />
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q002"
                  subsectionLabel="Property"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Property
                </SharedChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent>
                  <ChartRow>
                    <ChartRowElement
                      type="label"
                      label="patient property given to:"
                      fontStyle="italic"
                      textDecor="underline"
                    />
                    <ChartRowElement
                      type="option"
                      label="patient"
                      name="subsections.Q002.T001xSDISQ002A001"
                    />
                    <ChartRowElement
                      type="option"
                      label="family"
                      name="subsections.Q002.T001xSDISQ002A002"
                    />
                    <ChartRowElement
                      type="option"
                      label="security"
                      name="subsections.Q002.T001xSDISQ002A003"
                    />
                    <ChartRowElement
                      type="option"
                      label="see patient belongings list"
                      name="subsections.Q002.T001xSDISQ002A005"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q002.T001xSDISQ002A006"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="other"
                      name="subsections.Q002.T001xSDISQ002A007"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q002.T001xSDISQ002A008"
                    />
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q003"
                  subsectionLabel="Discharge Vitals"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Discharge Vitals
                </SharedChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent>
                  <ChartRow>
                    <Card
                      display="grid"
                      gridTemplateColumns="200px repeat(8, 1fr) 30px"
                      bg="gray.250"
                      boxShadow="none"
                      height="2.5rem"
                      alignItems="center"
                      borderRadius="5px"
                      padding={CONTAINER_PADDING}
                    >
                      <chakra.span color="gray.650" justifySelf="center">
                        Date / Time
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        BP
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        Pulse
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        RR
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        Temp
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        SpO2
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        GCS
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        Rythm
                      </chakra.span>
                      <chakra.span color="gray.650" justifySelf="center">
                        Pain
                      </chakra.span>
                      <Icon
                        as={EmrProfileComplete}
                        fontSize="1.25rem"
                        color="gray.650"
                        justifySelf="center"
                      />
                    </Card>
                  </ChartRow>
                  <ChartRow
                    templateColumns="200px repeat(8, 1fr) 30px"
                    maxW="78vw"
                  >
                    <ChartRowElement
                      type="date"
                      name="subsections.Q003.T001xSDISQ003A001"
                    />

                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A002"
                    />

                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A003"
                    />

                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A004"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A005"
                    />

                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A006"
                    />

                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A007"
                    />

                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A008"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q003.T001xSDISQ003A008"
                    />
                    <StaffAvatar
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName=""
                      profileUrl=""
                      justifySelf="center"
                    />
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q004"
                  subsectionLabel="Condition"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Condition
                </SharedChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="unchanged"
                      name="subsections.Q004.T001xSDISQ004A001"
                    />
                    <ChartRowElement
                      type="option"
                      label="improved"
                      name="subsections.Q004.T001xSDISQ004A002"
                    />
                    <ChartRowElement
                      type="option"
                      label="stable"
                      name="subsections.Q004.T001xSDISQ004A003"
                    />
                    <ChartRowElement
                      type="option"
                      label="other"
                      name="subsections.Q004.T001xSDISQ004A004"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q004.T001xSDISQ004A005"
                    />
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q005"
                  subsectionLabel="Disposition"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Disposition
                </SharedChartSubsectionHeader>
              }
              content={
                <>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="discharged:"
                        name="subsections.Q005.T001xSDISQ005A01"
                      />
                      <ChartRowElement
                        type="option"
                        label="home"
                        name="subsections.Q005.T001xSDISQ005A02"
                      />
                      <ChartRowElement
                        type="option"
                        label="nursing home"
                        name="subsections.Q005.T001xSDISQ005A03"
                      />
                      <ChartRowElement
                        type="option"
                        label="police"
                        name="subsections.Q005.T001xSDISQ005A04"
                      />
                      <ChartRowElement
                        type="option"
                        label="medical examiner"
                        name="subsections.Q005.T001xSDISQ005A05"
                      />
                      <ChartRowElement
                        type="option"
                        label="funeral home"
                        name="subsections.Q005.T001xSDISQ005A06"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A07"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="label"
                        label="instructions:"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="option"
                        label="verbal"
                        name="subsections.Q005.T001xSDISQ005A08"
                      />
                      <ChartRowElement
                        type="option"
                        label="written"
                        name="subsections.Q005.T001xSDISQ005A09"
                      />

                      <ChartRowElement
                        type="label"
                        label="to:"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A014"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="Rx given:"
                        fontStyle="italic"
                        textDecor="underline"
                        name="subsections.Q005.T001xSDISQ005A010"
                      />
                      <ChartRowElement
                        type="option"
                        label="one"
                        name="subsections.Q005.T001xSDISQ005A011"
                      />
                      <ChartRowElement
                        type="option"
                        label="multi"
                        name="subsections.Q005.T001xSDISQ005A012"
                      />
                      <ChartRowElement
                        type="label"
                        label="to:"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A054"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="verbalized understanding"
                        name="subsections.Q005.T001xSDISQ005A015"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A016"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="accompanied by:"
                        fontStyle="italic"
                        textDecor="underline"
                        name="subsections.Q005.T001xSDISQ005A017"
                      />
                      <ChartRowElement
                        type="option"
                        label="driver"
                        name="subsections.Q005.T001xSDISQ005A018"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A019"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="label"
                        label="notified"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="option"
                        label="family"
                        name="subsections.Q005.T001xSDISQ005A020"
                      />
                      <ChartRowElement
                        type="option"
                        label="police"
                        name="subsections.Q005.T001xSDISQ005A021"
                      />
                      <ChartRowElement
                        type="option"
                        label="medical examiner"
                        name="subsections.Q005.T001xSDISQ005A022"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A023"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A055"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="admitted to:"
                        name="subsections.Q005.T001xSDISQ005A024"
                        fontStyle="italic"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A056"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="transferred to"
                        name="subsections.Q005.T001xSDISQ005A025"
                        fontStyle="italic"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A026"
                      />
                    </ChartRow>
                    <ChartRow display="flex">
                      <ChartRowElement type="label" label="report to:" />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A027"
                        flex={1}
                      />
                      <ChartRowElement
                        type="label"
                        label="date & time:"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A028"
                        size="xl"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="label"
                        label="advance directive"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="option"
                        label="unavailable"
                        name="subsections.Q005.T001xSDISQ005A029"
                      />
                      <ChartRowElement
                        type="option"
                        label="added to chart"
                        name="subsections.Q005.T001xSDISQ005A030"
                      />
                      <ChartRowElement
                        type="option"
                        label="discussed w/ pt family"
                        name="subsections.Q005.T001xSDISQ005A031"
                      />
                      <ChartRowElement
                        type="option"
                        label="instructed to bring copy"
                        name="subsections.Q005.T001xSDISQ005A032"
                      />
                      <ChartRowElement
                        type="option"
                        label="information provided"
                        name="subsections.Q005.T001xSDISQ005A033"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A034"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A056"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="left AMA"
                        name="subsections.Q005.T001xSDISQ005A037"
                      />
                      <ChartRowElement
                        type="option"
                        label="LWBS"
                        name="subsections.Q005.T001xSDISQ005A038"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A039"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="signed AMA sheet"
                        name="subsections.Q005.T001xSDISQ005A040"
                      />
                      <ChartRowElement
                        type="option"
                        label="refused"
                        name="subsections.Q005.T001xSDISQ005A041"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A042"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="physician notified:"
                        name="subsections.Q005.T001xSDISQ005A043"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A044"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="label"
                        label="mode:"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="option"
                        label="walk"
                        name="subsections.Q005.T001xSDISQ005A045"
                      />
                      <ChartRowElement
                        type="option"
                        label="crutches"
                        name="subsections.Q005.T001xSDISQ005A046"
                      />
                      <ChartRowElement
                        type="option"
                        label="W/C"
                        name="subsections.Q005.T001xSDISQ005A047"
                      />
                      <ChartRowElement
                        type="option"
                        label="stretcher"
                        name="subsections.Q005.T001xSDISQ005A048"
                      />
                      <ChartRowElement
                        type="option"
                        label="ambulance"
                        name="subsections.Q005.T001xSDISQ005A049"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A050"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="label"
                        label="depart date & time:"
                        fontStyle="italic"
                        textDecor="underline"
                      />
                      <ChartRowElement
                        type="date"
                        name="subsections.Q005.T001xSDISQ005A051"
                        size="xl"
                      />
                      <ChartRowElement type="empty" />
                    </ChartRow>
                  </ChartSubsectionContent>
                </>
              }
            />
            <DispositionSignatureSubsection
              signatures={signatures}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              chartType={chartType}
              control={control}
            />

            <Flex justifyContent="center" width="100%" pt={8} pb={16} gap={30}>
              <Button
                variant="outlineSquared"
                borderRadius="25px"
                width="320px"
                isDisabled={
                  signatures.length === 0 ||
                  signatures?.some((signature) => !signature.user?.signature) ||
                  isLoadingEncounterDispositionUpdate
                }
                onClick={onDisposistionPatientAction}
                isLoading={isLoadingEncounterDispositionUpdate}
              >
                {!encounter?.data?.nurseDisposition
                  ? "Request Disposition"
                  : "Undo Disposition"}
              </Button>

              <WarningDialog
                isOpen={isOpen}
                onCancel={onCloseWarningDialog}
                title="Warning"
                mainText={warningDialogMessage}
                secondaryText={warningDialogSubMessage}
                onClose={onCloseWarningDialog}
                onAction={() => warningDialogAction && warningDialogAction()}
                cancelLabel="No"
                actionLabel="Yes"
                blockScrollOnMount={false}
                cancelButtonProps={{ color: "red" }}
                actionButtonProps={{ color: "blue" }}
              />
            </Flex>
          </ChartForm>
        </Box>

        <SidebarHardStops
          data={hardStops?.data}
          chartCode={chartCode}
          chartType={chartType}
          patientDispositioned={!!encounter?.data?.nurseDisposition}
        />
      </Box>
    </FormProvider>
  );
}

export { N07Disposition };
